$blue: #23c3e7;
$green: #97d101;
$pink: #ff75a5;


/**
cards
 */
$cardBackground: #dadada;
$cardBackgroundDark: #a2a2a2;



/**
sidebar
 */
$sidebarBackground: #e5e5e5;
$sidebarWidth: 250px;


/**
elements
*/
$elementBackground: #acacacad;
$elementBackgroundDark: #63636396;